import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { XIcon } from 'lucide-react';
import { Montserrat } from 'next/font/google';
import { Fragment } from 'react';

const montserrat = Montserrat({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
});

type MobileDrawerProps = {
  show: boolean;
  dismiss: () => void;
  title: string;
  children: React.ReactNode;
  headerExtraButton?: React.ReactNode;
  fullScreen?: boolean;
};

export default function MobileDrawer({
  show,
  dismiss,
  title,
  children,
  headerExtraButton,
  fullScreen = false,
}: MobileDrawerProps) {
  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={dismiss}
      >
        <div
          className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={dismiss}
        />

        <div className="fixed inset-x-0 bottom-0 flex w-full">
          <TransitionChild
            as={Fragment}
            enter="transform transition ease-in-out duration-300 sm:duration-500"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leave="transform transition ease-in-out duration-300 sm:duration-500"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            <div
              className={`min-h-[40vh] w-full overflow-y-auto rounded-t-[4rem] bg-white ${
                montserrat.className
              } text-black ${fullScreen ? 'min-h-[90vh] rounded-none' : ''}`}
            >
              <div className="relative flex w-full items-center justify-center border-b border-[#e6e5e5] pb-8 pt-12">
                {headerExtraButton && (
                  <div className="absolute left-16">{headerExtraButton}</div>
                )}

                <h1 className="whitespace-nowrap text-[2.1rem] font-semibold">
                  {title}
                </h1>

                <button onClick={dismiss} className="absolute right-16">
                  <XIcon className="h-[3rem] w-[3rem] " />
                </button>
              </div>

              {children}
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
