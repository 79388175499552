'use client';

import FormInput from '@c/forms/controls/FormInput';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { useAuth } from 'context/AuthContext';
import { ChatDocument } from 'models/chat';
import { createChat } from '@util/firestore/offers';
import { useChat } from 'context/ChatContext';
import useMultiImageUpload from '@util/hooks/useMultiImageUpload';
import { useDropzone } from 'react-dropzone';
import {
  AddPhotoBlock,
  EmptyPhotoBlock,
  PhotoBlock,
} from 'features/homepage/components/Home/components/TradeInProgram';
import Button from '@ui/Button';
import FormSelect from '@c/forms/controls/FormSelect';
import { useRouter } from 'next/navigation';

type UpgradeBootFormProps = {
  dismiss: () => void;
};

const formSchema = z.object({
  photos: z
    .array(
      z.object({
        full: z.string(),
        thumb: z.string(),
        mime_type: z.string().optional(),
        download_url: z.string().optional(),
      })
    )
    .min(3, 'Must have at least 3 photos'),
  currentSize: z.string().min(1, 'Please select a size'),
  request: z.string().min(1, 'Please enter a request'),
});

type Form = z.infer<typeof formSchema>;

const bootSizeOptions = [
  {
    id: '7',
    label: '7',
    value: '7',
  },
  {
    id: '7.5',
    label: '7.5',
    value: '7.5',
  },
  {
    id: '8',
    label: '8',
    value: '8',
  },
  {
    id: '8.5',
    label: '8.5',
    value: '8.5',
  },
  {
    id: '9',
    label: '9',
    value: '9',
  },
  {
    id: '9.5',
    label: '9.5',
    value: '9.5',
  },
  {
    id: '10',
    label: '10',
    value: '10',
  },
  {
    id: '10.5',
    label: '10.5',
    value: '10.5',
  },
  {
    id: '11',
    label: '11',
    value: '11',
  },
  {
    id: '11.5',
    label: '11.5',
    value: '11.5',
  },
  {
    id: '12',
    label: '12',
    value: '12',
  },
  {
    id: '12.5',
    label: '12.5',
    value: '12.5',
  },
  {
    id: '13',
    label: '13',
    value: '13',
  },
  {
    id: '13.5',
    label: '13.5',
    value: '13.5',
  },
  {
    id: '14',
    label: '14',
    value: '14',
  },
  {
    id: '14.5',
    label: '14.5',
    value: '14.5',
  },
  {
    id: '15',
    label: '15',
    value: '15',
  },
];

export default function UpgradeBootForm({ dismiss }: UpgradeBootFormProps) {
  const { userDoc } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { openChatDrawer } = useChat();
  const router = useRouter();

  const form = useForm<Form>({
    defaultValues: {
      photos: [],
      request: '',
    },
    resolver: zodResolver(formSchema),
  });

  async function onValid(formData: Form) {
    if (!userDoc) {
      router.push('/login');
      return;
    }

    const data = {
      currentSize: formData.currentSize,
      request: formData.request,
      photos: formData.photos,
    };
    setIsSubmitting(true);

    if (data.photos.length < 3) {
      form.setError('photos', {
        type: 'manual',
        message: 'Please upload at least 3 photos',
      });
      return;
    }

    const content = `New Boot Trade-In Request: Trading in size ${data.currentSize} | Requesting: ${data.request}`;

    const messages = [
      {
        uid: userDoc.uid,
        content: content,
        created_at: Date.now(),
        is_img: false,
        is_auto: false,
      },
      ...data.photos.map((photo) => ({
        uid: userDoc.uid,
        is_img: true,
        content: photo.full,
        thumbnail: photo.thumb,
        mime_type: photo.mime_type ?? '',
        download_url: photo.download_url ?? '',
        created_at: Date.now(),
        is_auto: false,
      })),
    ];

    const newChat: Omit<ChatDocument, 'id'> = {
      buyer_id: 'FjBH0nwiHgGV4Y0EXDQS', // MX Locker Account (not support)
      seller_id: userDoc.uid,
      uids: ['FjBH0nwiHgGV4Y0EXDQS', userDoc.uid],
      last_time: Date.now(),
      created_at: Date.now(),
      messages,
      unread: {
        ['FjBH0nwiHgGV4Y0EXDQS']: true,
      },
      from_web: true,
      flagged: false,
      is_expert: false,
      is_offer: false,
    };

    try {
      const id = await createChat(newChat);
      const chatDoc: ChatDocument = {
        ...newChat,
        id,
      };

      openChatDrawer(chatDoc);
      dismiss();
    } catch (e) {
      alert((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <form
      onSubmit={form.handleSubmit(onValid)}
      className="flex h-full flex-col justify-between"
    >
      <div className="flex flex-col gap-y-12">
        <div className="flex flex-col gap-y-4">
          <div className="flex items-start gap-x-1">
            <h3 className="text-[2.1rem] font-semibold text-black">
              Upload photos of your boots
            </h3>
            <span className="font-sembibold text-[2.1rem] text-brand-secondary">
              *
            </span>
          </div>

          <div className="flex items-center gap-x-6">
            <PhotoSelect form={form} />
          </div>

          <span className="text-[1.6rem] font-semibold text-[#444444]">
            Min. 3 photos
          </span>
        </div>

        <div className="flex flex-col gap-y-2 sm:max-w-[50rem]">
          <div className="flex items-start gap-x-1">
            <h3 className="text-[2.1rem] font-semibold text-black">
              What size are your boots?
            </h3>
            <span className="font-sembibold text-[2.1rem] text-brand-secondary">
              *
            </span>
          </div>

          <FormSelect
            control={form.control}
            options={bootSizeOptions}
            error={!!form.formState.errors.currentSize}
            {...form.register('currentSize')}
            placeholder="Select size"
            className="!rounded-[1rem] !text-[2.1rem] !font-medium sm:!text-[1.8rem]"
            onChange={(option) => {
              form.setValue('currentSize', option.value);
            }}
          />
          {form.formState.errors.currentSize && (
            <span className="text-[1.4rem] text-red-500">
              {form.formState.errors.currentSize.message}
            </span>
          )}
        </div>

        <div className="flex flex-col gap-y-2 sm:max-w-[50rem]">
          <div className="flex items-start gap-x-1">
            <h3 className="text-[2.1rem] font-semibold text-black">
              What boots you&apos;re looking for?
            </h3>
            <span className="font-sembibold text-[2.1rem] text-brand-secondary">
              *
            </span>
          </div>

          <FormInput
            placeholder="Your dream boots"
            error={!!form.formState.errors.request}
            {...form.register('request')}
            className="!rounded-[1rem] !text-[2.1rem] !font-medium sm:!text-[1.8rem]"
          />
          {form.formState.errors.request && (
            <span className="text-[1.4rem] text-red-500">
              {form.formState.errors.request.message}
            </span>
          )}
        </div>
      </div>

      <div className="mt-8 flex flex-col gap-y-4 sm:mt-0">
        <p className="text-[1.8rem] font-medium text-brand-secondary sm:max-w-[50rem]">
          *If boots arrive dirty or wet, they will be returned at your cost, and
          the trade-in will be invalid.
        </p>

        <Button
          type="secondary"
          buttonType="submit"
          className="flex cursor-pointer items-center justify-center rounded-[1.5rem] bg-brand-secondary p-4 text-[2.1rem] font-semibold text-white sm:w-[50rem]"
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Get Offer
        </Button>
      </div>
    </form>
  );
}

function PhotoSelect({ form }: { form: UseFormReturn<Form> }) {
  const photos = form.watch('photos');
  const { userDoc } = useAuth();
  const { uploadingImage, uploadImages, deleteImage } = useMultiImageUpload({
    id: userDoc?.uid,
    onUploadCallback: ({ paths, errors }) => {
      form.setValue('photos', [...photos, ...paths]);

      if (errors.length) {
        form.setError('photos', {
          type: 'manual',
          message: errors.join(', '),
        });
      }
    },
    onDeleteCallback: (paths) => form.setValue('photos', [...paths]),
    type: 'other',
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const isImageFile = acceptedFiles[0]?.type.includes('image');

      if (!isImageFile) {
        form.setError('photos', {
          type: 'manual',
          message: 'Only images are allowed',
        });
        return;
      }

      uploadImages(acceptedFiles).then(({ paths, errors }) => {
        if (paths.length) {
          form.setValue('photos', [...photos, ...paths]);
          form.trigger('photos'); // Trigger form update
        }

        if (errors.length) {
          form.setError('photos', {
            type: 'manual',
            message: errors.join(', '),
          });
        }
      });
    },
    [form, photos, uploadImages]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div className="flex w-full flex-wrap items-center gap-4">
      <AddPhotoBlock
        isUploadDisabled={false}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        uploadingImage={uploadingImage}
      />

      {photos.map((image, index) => (
        <PhotoBlock
          key={image.thumb}
          image={image}
          index={index}
          deleteImage={() => deleteImage(image, photos)}
          onMainImageSelect={() => {}}
          images={photos}
        />
      ))}

      {Array(Math.max(3 - photos.length, 0))
        .fill(null)
        .map((_, index) => (
          <EmptyPhotoBlock key={index} />
        ))}

      {form.formState.errors?.photos?.message && (
        <p className="text-[1.5rem] text-red-500 sm:text-[1.6rem]">
          {form.formState.errors?.photos?.message}
        </p>
      )}
    </div>
  );
}
