import CloseIcon from '@c/modals/assets/CloseIcon';
import BaseModal from '@c/modals/BaseModal';
import SafeImage from '@ui/SafeImage';
import { prefix } from '@util/imagePrefix';
import UpgradeBootForm from '../_forms/UpgradeBootForm';

type UpgradeNowModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function UpgradeNowModal({
  open,
  setOpen,
}: UpgradeNowModalProps) {
  return (
    <>
      <BaseModal isOpen={open} dismiss={() => setOpen(false)} hideCloseIcon>
        <div className="flex gap-x-16">
          <SafeImage
            src={`${prefix}/assets/boot-trade-in-modal-thumb_1920x2560.png`}
            alt="Boot Trade-In Program"
            height={1080}
            width={1920}
            className="h-[83rem] w-[55rem] object-cover"
          />

          <div className="flex w-[55rem] flex-col gap-y-12 py-10">
            <div className="flex flex-col gap-y-4">
              <div className="flex w-full items-center justify-between pr-16">
                <h1 className="text-[3rem] font-bold text-black">
                  Boot Trade In Program
                </h1>

                <button
                  type="button"
                  aria-label="Close"
                  onClick={() => setOpen(false)}
                  className="cursor-pointer"
                >
                  <CloseIcon className="h-12 w-12" />
                </button>
              </div>

              <p className="max-w-[50rem] text-[1.8rem] text-[#444444]">
                After submitting this form, a message thread with our trade in
                team will open and we will be in touch with you shortly.
              </p>
            </div>
            <UpgradeBootForm dismiss={() => setOpen(false)} />
          </div>
        </div>
      </BaseModal>
    </>
  );
}
