'use client';
import { EyeIcon, EyeSlashIcon } from '@c/icons';
import { InputStyles } from '@ui/Input';
import React, { useState } from 'react';

interface InputProps {
  placeholder?: string;
  error?: boolean;
  type?: 'password' | 'email' | 'text' | 'number' | 'date' | 'tel' | 'time';
  disabled?: boolean;
  readonly?: boolean;
  center?: boolean;
  defaultValue?: string | number;
  mask?: string;
  onDark?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: string | number;
  autoComplete?: string;
  unit?: 'lb' | 'in' | 'oz';
  maxLength?: number;
  minLength?: number;
  className?: string;
}

const FormInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      error,
      type = 'text',
      center,
      defaultValue,
      unit,
      onDark,
      readonly,
      disabled,
      className,
      ...rest
    },
    ref
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const getType = () => {
      if (type === 'password') {
        if (passwordVisible) return 'text';
        return 'password';
      }
      if (type === 'date' || type === 'time') {
        return type;
      }
      if (type === 'number') {
        return 'number';
      }
      if (type === 'tel') {
        return 'tel';
      }
      return 'text';
    };

    const handleTimeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
      if (value === '' || timeRegex.test(value)) {
        if (rest.onChange) {
          rest.onChange(e);
        }
      } else {
        e.preventDefault();
      }
    };

    return (
      <div
        className={`${InputStyles({
          type,
          onDark,
          full: true,
          error,
          focus: 'default',
          readonly,
          disabled,
        })}`}
      >
        <input
          placeholder={placeholder}
          className={`w-full border-none border-transparent bg-transparent p-0 !text-[2.1rem] leading-input text-inherit placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-input ${
            center ? 'text-center' : ''
          } ${className || ''}`}
          type={getType()}
          ref={ref}
          defaultValue={defaultValue}
          disabled={!!disabled}
          step={type === 'number' ? 'any' : undefined}
          onChange={type === 'time' ? handleTimeInput : rest.onChange}
          {...rest}
        />
        {type === 'password' && (
          <div
            className="cursor-pointer text-brand-gray"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? <EyeIcon /> : <EyeSlashIcon />}
          </div>
        )}
        {!!unit && <div>{unit}</div>}
      </div>
    );
  }
);
FormInput.displayName = 'FormInput';
export default FormInput;
