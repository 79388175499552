import MobileDrawer from '@c/drawers/MobileDrawer';
import UpgradeBootForm from '../_forms/UpgradeBootForm';

type UpgradeNowMobileModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function UpgradeNowMobileModal({
  open,
  setOpen,
}: UpgradeNowMobileModalProps) {
  return (
    <MobileDrawer
      show={open}
      dismiss={() => setOpen(false)}
      title="Boot Trade-In Program"
    >
      <div className="flex flex-col gap-y-4 p-6">
        <p className="text-[1.8rem] text-[#444444]">
          After submitting this form, a message thread with our trade in team
          will open and we will be in touch with you shortly.
        </p>

        <UpgradeBootForm dismiss={() => setOpen(false)} />
      </div>
    </MobileDrawer>
  );
}
