'use client';

import { useState } from 'react';
import UpgradeNowModal from './UpgradeNowModal';
import UpgradeNowMobileModal from './UpgradeNowMobileModal';
import { isMobile } from '@util/index';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/navigation';
import { useAuth } from 'context/AuthContext';

type UpgradeNowTriggerProps = {
  title?: string;
};

export default function UpgradeNowTrigger({
  title = 'Upgrade Now',
}: UpgradeNowTriggerProps) {
  const [open, setOpen] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const { userDoc } = useAuth();
  const pathname = usePathname();
  const router = useRouter();

  const onOpenChange = () => {
    if (pathname === '/') {
      router.push('/boot-trade-in-program');
    } else {
      if (userDoc) {
        if (isMobile()) {
          setOpenMobile(true);
        } else {
          setOpen(!open);
        }
      } else {
        router.push('/login');
      }
    }
  };

  return (
    <>
      <button
        type="button"
        className="mt-4 w-fit rounded-[2rem] bg-brand-secondary p-6 text-[2.1rem] font-semibold text-white sm:text-[1.8rem]"
        onClick={onOpenChange}
      >
        {title}
      </button>

      <UpgradeNowModal open={open} setOpen={setOpen} />
      <UpgradeNowMobileModal open={openMobile} setOpen={setOpenMobile} />
    </>
  );
}
